import React, { useState, useEffect } from "react";
import {
    ShoppingCart,
    Zap,
    CreditCard,
    Shield,
    Check,
    Download,
    Copy,
} from "lucide-react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import VisualizationSettings from "./VisualizationSettings";

const API_BASE_URL = "https://api.astrashopbot.cc";

export default function UserSettings({ updatePurchaseMode, setMenuOpen }) {
    const [purchaseMode, setPurchaseMode] = useState("cart");
    const [checkerEnabled, setCheckerEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [updatingPurchaseMode, setUpdatingPurchaseMode] = useState(null);
    const [isUpdatingChecker, setIsUpdatingChecker] = useState(false);
    const [checkerOption, setCheckerOption] = useState("debitando");
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationStep, setVerificationStep] = useState(0);
    const [backupCodes, setBackupCodes] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [isDisabling2FA, setIsDisabling2FA] = useState(false);
    const [disableCode, setDisableCode] = useState("");

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const fetchUserSettings = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/api/user-settings`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );

            const settings = response.data.settings;
            setPurchaseMode(settings.purchaseMode || "cart");
            setCheckerEnabled(!!settings.checkerEnabled);
            setCheckerOption(settings.checkerOption || "debitando");

            // Se 2FA já tá ativo e configurado
            if (response.data.user["2fa_enabled"]) {
                setIs2FAEnabled(true);
                setVerificationStep(-1); // Indica que já tá configurado
            } else {
                setIs2FAEnabled(false);
                setVerificationStep(0); // Reset pro início da configuração
            }
        } catch (error) {
            console.error("Erro ao buscar as configurações do usuário:", error);
            toast.error("Erro ao buscar as configurações do usuário.");
        } finally {
            setLoading(false);
        }
    };

    const updateUserSettings = async (newSettings) => {
        try {
            await axios.post(
                `${API_BASE_URL}/api/user-settings`,
                { settings: newSettings },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
        } catch (error) {
            throw error;
        }
    };

    const handlePurchaseModeChange = async (value) => {
        setPurchaseMode(value);
        setUpdatingPurchaseMode(value);
        try {
            await updateUserSettings({
                purchaseMode: value,
                checkerEnabled,
                checkerOption,
            });
            updatePurchaseMode(value);
        } catch (error) {
            console.error("Erro ao atualizar o modo de compra:", error);
            toast.error("Erro ao atualizar o modo de compra.");
            fetchUserSettings();
        } finally {
            setUpdatingPurchaseMode(null);
        }
    };

    const handleCheckerToggle = async (checked) => {
        setCheckerEnabled(checked);
        setIsUpdatingChecker(true);
        try {
            await updateUserSettings({
                purchaseMode,
                checkerEnabled: checked,
                checkerOption: checked ? checkerOption : undefined,
            });
            if (!checked) {
                setCheckerOption("debitando");
            }
        } catch (error) {
            console.error("Erro ao atualizar o checker:", error);
            toast.error("Erro ao atualizar o checker.");
            fetchUserSettings();
        } finally {
            setIsUpdatingChecker(false);
        }
    };

    const handleCheckerOptionChange = async (value) => {
        setCheckerOption(value);
        try {
            await updateUserSettings({
                purchaseMode,
                checkerEnabled,
                checkerOption: value,
            });
        } catch (error) {
            console.error("Erro ao atualizar a opção do checker:", error);
            toast.error("Erro ao atualizar a opção do checker.");
            fetchUserSettings();
        }
    };

    const handleToggle2FA = async (checked, code = null) => {
        try {
            if (checked) {
                // Iniciar configuração do 2FA
                const response = await axios.get(
                    `${API_BASE_URL}/api/2fa/setup`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );
                setQrCode(response.data.qr_code);
                setIs2FAEnabled(true);
                setVerificationStep(0);
            } else {
                // Se está tentando desativar, primeiro abre o modal
                if (!code) {
                    setIsDisabling2FA(true);
                    // Importante: retorna aqui para não prosseguir com a desativação
                    return;
                }

                // Se tem código, prossegue com a desativação
                await axios.post(
                    `${API_BASE_URL}/api/2fa/disable`,
                    { code },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );
                setIs2FAEnabled(false);
                setVerificationStep(-1);
                setBackupCodes(null);
            }
        } catch (error) {
            throw error;
        }
    };

    const handleVerifyCode = async () => {
        try {
            setVerificationStep(1); // Mostra loading
            const response = await axios.post(
                `${API_BASE_URL}/api/2fa/verify`,
                { code: verificationCode },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );

            // Se chegou aqui, deu certo
            setVerificationStep(2);
            setBackupCodes(response.data.backup_codes);
            toast.success("2FA ativado com sucesso!");

            // Atualiza o estado local
            setIs2FAEnabled(true);
        } catch (error) {
            console.error("Erro ao verificar código 2FA:", error);
            toast.error(error.response?.data?.error || "Código inválido");
            setVerificationStep(0); // Volta pro começo em caso de erro
        }
    };

    const handleDownloadCodes = () => {
        if (!backupCodes) return;
        const codesText = backupCodes.join("\n");
        const blob = new Blob([codesText], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "backup-codes.txt";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleCopyCodes = () => {
        if (!backupCodes) return;
        navigator.clipboard.writeText(backupCodes.join("\n"));
        toast.success("Códigos copiados para a área de transferência!");
    };

    return (
        <div className="container mx-auto px-4 pt-8">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
            />
            <div className="max-w-2xl mx-auto">
                {loading ? (
                    <div className="flex flex-col items-center justify-center h-64 space-y-4">
                        <Spinner />
                        <div className="text-lg font-semibold">
                            Carregando configurações...
                        </div>
                    </div>
                ) : (
                    <div className="bg-card text-card-foreground shadow-sm rounded-lg border p-6">
                        <h1 className="text-2xl font-bold mb-6">
                            Configurações de Usuário
                        </h1>
                        <div className="space-y-6">
                            <div>
                                <h2 className="text-lg font-medium mb-3">
                                    Modo de Compra
                                </h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    <PurchaseModeButton
                                        icon={
                                            <ShoppingCart className="w-8 h-8" />
                                        }
                                        label="Usar Carrinho"
                                        isSelected={purchaseMode === "cart"}
                                        onClick={() =>
                                            handlePurchaseModeChange("cart")
                                        }
                                        isLoading={
                                            updatingPurchaseMode === "cart"
                                        }
                                    />
                                    <PurchaseModeButton
                                        icon={<Zap className="w-8 h-8" />}
                                        label="Compra Instantânea"
                                        isSelected={purchaseMode === "instant"}
                                        onClick={() =>
                                            handlePurchaseModeChange("instant")
                                        }
                                        isLoading={
                                            updatingPurchaseMode === "instant"
                                        }
                                    />
                                    <PurchaseModeButton
                                        icon={
                                            <CreditCard className="w-8 h-8" />
                                        }
                                        label="Compra Inteligente"
                                        isSelected={purchaseMode === "smart"}
                                        onClick={() =>
                                            handlePurchaseModeChange("smart")
                                        }
                                        isLoading={
                                            updatingPurchaseMode === "smart"
                                        }
                                    />
                                </div>
                            </div>

                            <div className="pt-6 border-t">
                                <VisualizationSettings />
                            </div>

                            <div className="bg-secondary p-3 rounded-lg">
                                <div className="flex items-center justify-between">
                                    <div className="space-y-0.5">
                                        <label
                                            htmlFor="checker-toggle"
                                            className="text-base cursor-pointer"
                                        >
                                            Checker
                                        </label>
                                        <p className="text-sm text-muted-foreground">
                                            Verificador automático
                                        </p>
                                    </div>
                                    <ToggleSwitch
                                        id="checker-toggle"
                                        checked={checkerEnabled}
                                        onCheckedChange={handleCheckerToggle}
                                        disabled={isUpdatingChecker}
                                    />
                                </div>
                            </div>

                            <AnimatePresence>
                                {checkerEnabled && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: "auto" }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="space-y-3"
                                    >
                                        <h2 className="text-lg font-medium">
                                            Opção do Checker
                                        </h2>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <CheckerOptionButton
                                                icon={
                                                    <CreditCard className="w-8 h-8" />
                                                }
                                                label="Debitando"
                                                isSelected={
                                                    checkerOption ===
                                                    "debitando"
                                                }
                                                onClick={() =>
                                                    handleCheckerOptionChange(
                                                        "debitando",
                                                    )
                                                }
                                            />
                                            <CheckerOptionButton
                                                icon={
                                                    <Shield className="w-8 h-8" />
                                                }
                                                label="ZeroAuth"
                                                isSelected={
                                                    checkerOption === "zeroauth"
                                                }
                                                onClick={() =>
                                                    handleCheckerOptionChange(
                                                        "zeroauth",
                                                    )
                                                }
                                            />
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            <div className="pt-6 border-t">
                                <h2 className="text-lg font-medium mb-3">
                                    Autenticação de Dois Fatores (2FA)
                                </h2>

                                <div className="bg-secondary p-3 rounded-lg mb-4">
                                    <div className="flex items-center justify-between">
                                        <div className="space-y-0.5">
                                            <label
                                                htmlFor="2fa-toggle"
                                                className="text-base cursor-pointer"
                                            >
                                                2FA
                                            </label>
                                            <p className="text-xs sm:text-sm text-muted-foreground">
                                                Adicione uma camada extra de
                                                segurança
                                            </p>
                                        </div>
                                        <ToggleSwitch
                                            id="2fa-toggle"
                                            checked={is2FAEnabled}
                                            onCheckedChange={(newValue) => {
                                                // Se está tentando ativar, segue o fluxo normal
                                                if (newValue) {
                                                    handleToggle2FA(true);
                                                } else {
                                                    // Se está tentando desativar, só abre o modal
                                                    handleToggle2FA(false);
                                                    // Importante: mantenha o switch ligado até a confirmação
                                                    setIs2FAEnabled(true);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <AnimatePresence>
                                    {is2FAEnabled && (
                                        <motion.div
                                            key="2fa-content"
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{
                                                opacity: 1,
                                                height: "auto",
                                            }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            {verificationStep === -1 ? ( // Se já tá configurado e ativo
                                                // Mostra mensagem de "2FA está ativo"
                                                <>
                                                    <div className="bg-accent border-l-4 border-primary text-accent-foreground p-4 rounded-r-lg">
                                                        <div className="flex items-center">
                                                            <Check className="h-5 w-5 mr-2" />
                                                            <div>
                                                                <p className="font-bold">
                                                                    2FA está
                                                                    ativo e
                                                                    funcionando
                                                                </p>
                                                                <p className="text-sm">
                                                                    Sua conta
                                                                    está
                                                                    protegida
                                                                    com
                                                                    autenticação
                                                                    de dois
                                                                    fatores.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() =>
                                                                setIsDisabling2FA(
                                                                    true,
                                                                )
                                                            }
                                                            className="mt-4 w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                                                        >
                                                            Desativar 2FA
                                                        </button>
                                                    </div>

                                                    {/* Modal de Desativação */}
                                                    <AnimatePresence>
                                                        {isDisabling2FA && (
                                                            <>
                                                                {/* Overlay */}
                                                                <motion.div
                                                                    initial={{
                                                                        opacity: 0,
                                                                    }}
                                                                    animate={{
                                                                        opacity: 1,
                                                                    }}
                                                                    exit={{
                                                                        opacity: 0,
                                                                    }}
                                                                    className="fixed inset-0 bg-black/50 z-50"
                                                                    onClick={() => {
                                                                        setIsDisabling2FA(
                                                                            false,
                                                                        );
                                                                        setDisableCode(
                                                                            "",
                                                                        );
                                                                    }}
                                                                />

                                                                {/* Modal com novo posicionamento */}
                                                                <motion.div
                                                                    initial={{
                                                                        opacity: 0,
                                                                        y: 50,
                                                                    }}
                                                                    animate={{
                                                                        opacity: 1,
                                                                        y: 0,
                                                                    }}
                                                                    exit={{
                                                                        opacity: 0,
                                                                        y: 50,
                                                                    }}
                                                                    className="fixed inset-x-0 bottom-0 z-50 bg-card border-t border-border rounded-t-lg shadow-lg"
                                                                >
                                                                    <div className="p-5 max-h-[90vh] overflow-y-auto">
                                                                        <div className="max-w-md mx-auto">
                                                                            <h3 className="text-lg font-semibold mb-2">
                                                                                Desativar
                                                                                2FA
                                                                            </h3>
                                                                            <p className="text-sm text-muted-foreground mb-4">
                                                                                Para
                                                                                desativar
                                                                                o
                                                                                2FA,
                                                                                digite
                                                                                o
                                                                                código
                                                                                atual
                                                                                do
                                                                                seu
                                                                                aplicativo
                                                                                autenticador
                                                                                ou
                                                                                use
                                                                                um
                                                                                código
                                                                                de
                                                                                backup.
                                                                            </p>

                                                                            <div className="space-y-4">
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Digite o código de 6 dígitos"
                                                                                    className="w-full px-3 py-2 bg-secondary text-secondary-foreground border border-input rounded-lg focus:outline-none focus:ring-2 focus:ring-ring"
                                                                                    maxLength={
                                                                                        6
                                                                                    }
                                                                                    value={
                                                                                        disableCode
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        const onlyNumbers =
                                                                                            e.target.value.replace(
                                                                                                /[^0-9]/g,
                                                                                                "",
                                                                                            );
                                                                                        setDisableCode(
                                                                                            onlyNumbers,
                                                                                        );
                                                                                    }}
                                                                                />

                                                                                <div className="flex flex-col gap-2 pb-safe">
                                                                                    <button
                                                                                        className="w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:bg-red-400"
                                                                                        disabled={
                                                                                            disableCode.length !==
                                                                                            6
                                                                                        }
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                await handleToggle2FA(
                                                                                                    false,
                                                                                                    disableCode,
                                                                                                );
                                                                                                toast.success(
                                                                                                    "2FA desativado com sucesso!",
                                                                                                );
                                                                                                setIsDisabling2FA(
                                                                                                    false,
                                                                                                );
                                                                                                setDisableCode(
                                                                                                    "",
                                                                                                );
                                                                                            } catch (error) {
                                                                                                toast.error(
                                                                                                    error
                                                                                                        .response
                                                                                                        ?.data
                                                                                                        ?.error ||
                                                                                                        "Erro ao desativar 2FA",
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Desativar
                                                                                    </button>
                                                                                    <button
                                                                                        className="w-full px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors"
                                                                                        onClick={() => {
                                                                                            setIsDisabling2FA(
                                                                                                false,
                                                                                            );
                                                                                            setDisableCode(
                                                                                                "",
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Cancelar
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </motion.div>
                                                            </>
                                                        )}
                                                    </AnimatePresence>
                                                </>
                                            ) : (
                                                // Processo de configuração inicial do 2FA
                                                <>
                                                    {verificationStep === 0 && (
                                                        <div className="space-y-4">
                                                            {qrCode && (
                                                                <div className="bg-secondary p-4 w-full aspect-square max-w-[240px] mx-auto flex items-center justify-center border border-border rounded-lg mb-3">
                                                                    <img
                                                                        src={`data:image/png;base64,${qrCode}`}
                                                                        alt="QR Code para 2FA"
                                                                        className="w-full h-full"
                                                                    />
                                                                </div>
                                                            )}
                                                            <ol className="list-decimal list-inside space-y-2 text-sm text-muted-foreground mb-4">
                                                                <li>
                                                                    Instale um
                                                                    aplicativo
                                                                    autenticador
                                                                    como Google
                                                                    Authenticator
                                                                    ou Authy
                                                                </li>
                                                                <li>
                                                                    Abra o
                                                                    aplicativo e
                                                                    escaneie o
                                                                    QR Code
                                                                    acima
                                                                </li>
                                                                <li>
                                                                    Digite o
                                                                    código de 6
                                                                    dígitos
                                                                    gerado no
                                                                    aplicativo
                                                                </li>
                                                            </ol>
                                                            <input
                                                                type="text"
                                                                placeholder="Digite o código de 6 dígitos"
                                                                className="w-full px-3 py-2 bg-secondary text-secondary-foreground border border-input rounded-lg focus:outline-none focus:ring-2 focus:ring-ring"
                                                                maxLength={6}
                                                                value={
                                                                    verificationCode
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const onlyNumbers =
                                                                        e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            "",
                                                                        );
                                                                    setVerificationCode(
                                                                        onlyNumbers,
                                                                    );
                                                                }}
                                                            />
                                                            <button
                                                                className="w-full px-4 py-2 bg-primary text-primary-foreground rounded-lg hover:bg-primary/90 transition-colors disabled:bg-muted disabled:text-muted-foreground"
                                                                disabled={
                                                                    verificationCode.length !==
                                                                    6
                                                                }
                                                                onClick={
                                                                    handleVerifyCode
                                                                }
                                                            >
                                                                Verificar
                                                            </button>
                                                        </div>
                                                    )}

                                                    {verificationStep === 1 && (
                                                        <div className="flex items-center justify-center py-8 text-muted-foreground">
                                                            <Spinner />
                                                            <span className="ml-2">
                                                                Verificando...
                                                            </span>
                                                        </div>
                                                    )}

                                                    {verificationStep === 2 && (
                                                        <motion.div
                                                            initial={{
                                                                opacity: 0,
                                                                y: 20,
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                                y: 0,
                                                            }}
                                                            transition={{
                                                                duration: 0.5,
                                                            }}
                                                        >
                                                            <div
                                                                className="bg-accent border-l-4 border-primary text-accent-foreground p-4 mb-4 rounded-r-lg"
                                                                role="alert"
                                                            >
                                                                <div className="flex">
                                                                    <Check className="h-5 w-5 mr-2" />
                                                                    <div>
                                                                        <p className="font-bold">
                                                                            2FA
                                                                            ativado
                                                                            com
                                                                            sucesso!
                                                                        </p>
                                                                        <p>
                                                                            Sua
                                                                            conta
                                                                            agora
                                                                            está
                                                                            mais
                                                                            segura.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="bg-card border border-border rounded-lg p-4">
                                                                <h3 className="text-lg font-semibold mb-2 text-card-foreground">
                                                                    Seus códigos
                                                                    de backup
                                                                </h3>
                                                                <p className="text-sm text-muted-foreground mb-4">
                                                                    Guarde estes
                                                                    códigos em
                                                                    um lugar
                                                                    seguro. Você
                                                                    pode usá-los
                                                                    para acessar
                                                                    sua conta
                                                                    caso perca
                                                                    acesso ao
                                                                    seu
                                                                    dispositivo
                                                                    2FA.
                                                                </p>
                                                                <div className="grid grid-cols-2 gap-2 mb-4">
                                                                    {backupCodes?.map(
                                                                        (
                                                                            code,
                                                                            index,
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="p-2 bg-secondary border border-border rounded text-center font-mono text-secondary-foreground"
                                                                            >
                                                                                {
                                                                                    code
                                                                                }
                                                                            </div>
                                                                        ),
                                                                    )}
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <button
                                                                        className="flex-1 px-4 py-2 bg-primary text-primary-foreground rounded-lg hover:bg-primary/90 transition-colors flex items-center justify-center"
                                                                        onClick={
                                                                            handleDownloadCodes
                                                                        }
                                                                    >
                                                                        <Download className="mr-2 h-4 w-4" />
                                                                        Baixar
                                                                        Códigos
                                                                    </button>
                                                                    <button
                                                                        className="flex-1 px-4 py-2 bg-secondary text-secondary-foreground rounded-lg hover:bg-secondary/90 transition-colors flex items-center justify-center"
                                                                        onClick={
                                                                            handleCopyCodes
                                                                        }
                                                                    >
                                                                        <Copy className="mr-2 h-4 w-4" />
                                                                        Copiar
                                                                        Códigos
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                </>
                                            )}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function PurchaseModeButton({
    icon,
    label,
    isSelected,
    onClick,
    isLoading,
    disabled,
    badge,
}) {
    return (
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
            className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
                isSelected
                    ? "bg-blue-600 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }`}
            disabled={isLoading}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {icon}
                    {isSelected && (
                        <Check className="absolute top-2 right-2 w-4 h-4 text-white" />
                    )}
                </>
            )}
            <span className="mt-2 text-sm text-center">{label}</span>
        </motion.button>
    );
}

function CheckerOptionButton({ icon, label, isSelected, onClick }) {
    return (
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
            className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
                isSelected
                    ? "bg-green-600 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }`}
        >
            {isSelected && (
                <Check className="absolute top-2 right-2 w-4 h-4 text-white" />
            )}
            {icon}
            <span className="mt-2 text-sm text-center">{label}</span>
        </motion.button>
    );
}

function ToggleSwitch({ checked, onCheckedChange, disabled }) {
    return (
        <button
            className={`w-12 h-7 rounded-full flex items-center p-0.5 ${
                checked
                    ? "bg-green-600 justify-end"
                    : "bg-gray-700 justify-start"
            }`}
            onClick={() => !disabled && onCheckedChange(!checked)}
            disabled={disabled}
        >
            <div className="bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-200" />
        </button>
    );
}

function Spinner() {
    return (
        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-current"></div>
    );
}
