import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosConfig";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import {
    X,
    ChevronDown,
    ChevronUp,
    CreditCard,
    Copy,
    Check,
} from "lucide-react";

const categoryStyles = {
    mastercard:
        "from-orange-600/10 via-orange-600/5 to-orange-600/0 text-orange-400",
    visa: "from-blue-600/10 via-blue-600/5 to-blue-600/0 text-blue-400",
    amex: "from-emerald-600/10 via-emerald-600/5 to-emerald-600/0 text-emerald-400",
};

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-lg border bg-card text-card-foreground shadow-sm",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex flex-col space-y-1.5 p-6", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={clsx(
            "text-2xl font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={clsx("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const Table = React.forwardRef(({ className, ...props }, ref) => (
    <div className="w-full overflow-auto">
        <table
            ref={ref}
            className={clsx("w-full caption-bottom text-sm", className)}
            {...props}
        />
    </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
    <thead
        ref={ref}
        className={clsx("[&_tr]:border-b", className)}
        {...props}
    />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
    <tbody
        ref={ref}
        className={clsx("[&_tr:last-child]:border-0", className)}
        {...props}
    />
));
TableBody.displayName = "TableBody";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
    <th
        ref={ref}
        className={clsx(
            "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
            className,
        )}
        {...props}
    />
));
TableHead.displayName = "TableHead";

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={clsx(
            "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
            className,
        )}
        {...props}
    />
));
TableRow.displayName = "TableRow";

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
    <td
        ref={ref}
        className={clsx(
            "p-4 align-middle [&:has([role=checkbox])]:pr-0",
            className,
        )}
        {...props}
    />
));
TableCell.displayName = "TableCell";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={handleOverlayClick}
        >
            <div className="relative bg-background rounded-lg shadow-lg w-11/12 max-w-lg p-6">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                {children}
            </div>
        </div>
    );
};

const MinhasCompras = () => {
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [expandedId, setExpandedId] = useState(null);
    const [timeFilter, setTimeFilter] = useState("month");
    const [isTimeFilterMenuOpen, setIsTimeFilterMenuOpen] = useState(false);

    const [copiedFields, setCopiedFields] = useState({}); // Novo estado

    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const menuRef = useRef(null);

    useEffect(() => {
        const fetchPurchaseHistory = async () => {
            try {
                const response = await axiosInstance.get("/purchase-history");
                setPurchaseHistory(response.data.purchase_history);
                setLoading(false);
            } catch (err) {
                console.error("Erro ao buscar histórico de compras:", err);
                setError("Falha ao carregar o histórico de compras.");
                setLoading(false);
            }
        };

        fetchPurchaseHistory();
    }, []);

    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    const openModal = (purchase) => {
        setSelectedPurchase(purchase);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedPurchase(null);
        setIsModalOpen(false);
    };

    // Função para fechar o menu ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsTimeFilterMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const copyToClipboard = (purchaseId, fieldName, text) => {
        navigator.clipboard.writeText(text);
        const key = `${purchaseId}-${fieldName}`;
        setCopiedFields((prev) => ({ ...prev, [key]: true }));

        // Remover o feedback após 2 segundos
        setTimeout(() => {
            setCopiedFields((prev) => ({ ...prev, [key]: false }));
        }, 2000);
    };

    if (loading) return <div>Carregando histórico de compras...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="w-full max-w-4xl mx-auto bg-neutral-900 border border-zinc-800 rounded-3xl shadow-xl backdrop-blur-xl">
            <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-xl font-semibold text-zinc-100 flex flex-col gap-2">
                        Histórico de Compras
                        <div className="flex items-center gap-3">
                            <p className="text-sm text-zinc-500">
                                {purchaseHistory.length} Compras
                            </p>
                        </div>
                    </h2>
                    {/* Substituindo o select por um menu dropdown personalizado */}
                    <div className="relative" ref={menuRef}>
                        <button
                            type="button"
                            onClick={() =>
                                setIsTimeFilterMenuOpen(!isTimeFilterMenuOpen)
                            }
                            className="flex items-center gap-1.5 hover:bg-black/5 dark:hover:bg-white/5 rounded-lg px-3 py-1.5 bg-zinc-800 border border-zinc-700 text-sm text-zinc-400 focus:outline-none focus:border-zinc-600"
                        >
                            <span>
                                {timeFilter === "month"
                                    ? "Este Mês"
                                    : timeFilter === "week"
                                      ? "Esta Semana"
                                      : "Hoje"}
                            </span>
                            <ChevronDown className="w-3 h-3 ml-0.5" />
                        </button>

                        {isTimeFilterMenuOpen && (
                            <div className="absolute top-full left-0 mt-1 bg-white dark:bg-zinc-800 rounded-md shadow-lg py-1 z-50 border border-black/10 dark:border-white/10">
                                {[
                                    { value: "month", label: "Este Mês" },
                                    { value: "week", label: "Esta Semana" },
                                    { value: "day", label: "Hoje" },
                                ].map((option) => (
                                    <button
                                        key={option.value}
                                        type="button"
                                        onClick={() => {
                                            setTimeFilter(option.value);
                                            setIsTimeFilterMenuOpen(false);
                                        }}
                                        className="w-full px-3 py-1.5 text-left hover:bg-black/5 dark:hover:bg-white/5 flex items-center gap-2 text-sm transition-colors"
                                    >
                                        <span>{option.label}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {isSmallScreen ? (
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Data</TableHead>
                                <TableHead>BIN</TableHead>
                                <TableHead>Ações</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {purchaseHistory.map((purchase, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {new Date(
                                            purchase.purchase_date,
                                        ).toLocaleString()}
                                    </TableCell>
                                    <TableCell>{purchase.bin}</TableCell>
                                    <TableCell>
                                        <button
                                            onClick={() => openModal(purchase)}
                                        >
                                            <FiSearch />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="space-y-4">
                        {purchaseHistory.map((purchase) => (
                            <div
                                key={purchase.id}
                                onClick={() => toggleExpand(purchase.id)}
                                className="group relative flex flex-col gap-2 p-3 -mx-3 rounded-2xl transition-all duration-300 ease-out hover:bg-zinc-800/50 hover:shadow-sm border border-transparent hover:border-zinc-700/50 cursor-pointer"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div
                                            className={`relative w-12 h-12 flex items-center justify-center rounded-2xl bg-gradient-to-br ${
                                                categoryStyles[
                                                    purchase.vendor.toLowerCase()
                                                ]
                                            } dark:from-blue-500/20 dark:via-blue-500/10 dark:to-transparent dark:text-blue-400 transition-all duration-300 group-hover:scale-105 group-hover:shadow-md border border-zinc-200/50 dark:border-zinc-700/50 shadow-sm`}
                                        >
                                            <CreditCard className="w-5 h-5" />
                                        </div>

                                        <div className="space-y-1 min-w-0">
                                            <h3 className="text-base font-medium text-zinc-100 flex items-center gap-2">
                                                <span className="font-mono">
                                                    {purchase.bin}
                                                </span>
                                                <span className="text-zinc-500">
                                                    |
                                                </span>
                                                <span className="truncate">
                                                    {purchase.nome}
                                                </span>
                                            </h3>
                                            <p className="text-sm text-zinc-400">
                                                {new Date(
                                                    purchase.purchase_date,
                                                ).toLocaleString()}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <div className="flex items-center gap-1 pr-2">
                                            <span className="text-base font-semibold text-zinc-100">
                                                R$ {purchase.price.toFixed(2)}
                                            </span>
                                        </div>

                                        <div className="p-2 hover:bg-zinc-700/50 rounded-full transition-colors duration-200">
                                            {expandedId === purchase.id ? (
                                                <ChevronUp className="w-5 h-5 text-zinc-400" />
                                            ) : (
                                                <ChevronDown className="w-5 h-5 text-zinc-400" />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {expandedId === purchase.id && (
                                    <div className="mt-4 pl-16 pr-4 space-y-3 text-sm border-l border-zinc-800">
                                        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                                            {/* Número */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Número
                                                </p>
                                                <div className="flex items-center">
                                                    <p className="text-zinc-100 font-mono">
                                                        {purchase.number}
                                                    </p>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                purchase.id,
                                                                "number",
                                                                purchase.number,
                                                            );
                                                        }}
                                                        className="ml-2"
                                                    >
                                                        {copiedFields[
                                                            `${purchase.id}-number`
                                                        ] ? (
                                                            <Check className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Validade */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Validade
                                                </p>
                                                <div className="flex items-center">
                                                    <p className="text-zinc-100 font-mono">
                                                        {purchase.month}/
                                                        {purchase.year}
                                                    </p>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                purchase.id,
                                                                "validade",
                                                                `${purchase.month}/${purchase.year}`,
                                                            );
                                                        }}
                                                        className="ml-2"
                                                    >
                                                        {copiedFields[
                                                            `${purchase.id}-validade`
                                                        ] ? (
                                                            <Check className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* CVV */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    CVV
                                                </p>
                                                <div className="flex items-center">
                                                    <p className="text-zinc-100 font-mono">
                                                        {purchase.cvv}
                                                    </p>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                purchase.id,
                                                                "cvv",
                                                                purchase.cvv,
                                                            );
                                                        }}
                                                        className="ml-2"
                                                    >
                                                        {copiedFields[
                                                            `${purchase.id}-cvv`
                                                        ] ? (
                                                            <Check className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Nome */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Nome
                                                </p>
                                                <div className="flex items-center">
                                                    <p className="text-zinc-100">
                                                        {purchase.nome}
                                                    </p>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                purchase.id,
                                                                "nome",
                                                                purchase.nome,
                                                            );
                                                        }}
                                                        className="ml-2"
                                                    >
                                                        {copiedFields[
                                                            `${purchase.id}-nome`
                                                        ] ? (
                                                            <Check className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* CPF */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    CPF
                                                </p>
                                                <div className="flex items-center">
                                                    <p className="text-zinc-100 font-mono">
                                                        {purchase.cpf}
                                                    </p>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(
                                                                purchase.id,
                                                                "cpf",
                                                                purchase.cpf,
                                                            );
                                                        }}
                                                        className="ml-2"
                                                    >
                                                        {copiedFields[
                                                            `${purchase.id}-cpf`
                                                        ] ? (
                                                            <Check className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Banco */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Banco
                                                </p>
                                                <p className="text-zinc-100">
                                                    {purchase.bank}
                                                </p>
                                            </div>
                                            {/* País */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    País
                                                </p>
                                                <p className="text-zinc-100">
                                                    {purchase.country}
                                                </p>
                                            </div>
                                            {/* Tipo */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Tipo
                                                </p>
                                                <p className="text-zinc-100">
                                                    {purchase.type}
                                                </p>
                                            </div>
                                            {/* Nível */}
                                            <div className="space-y-1">
                                                <p className="text-zinc-500">
                                                    Nível
                                                </p>
                                                <p className="text-zinc-100">
                                                    {purchase.level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {selectedPurchase && (
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <h2 className="text-xl font-bold mb-4">
                            Detalhes da Compra
                        </h2>
                        <div className="space-y-2">
                            {/* Conteúdo do modal */}
                        </div>
                        <button
                            onClick={closeModal}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Fechar
                        </button>
                    </Modal>
                )}
            </div>

            <div className="p-4 border-t border-zinc-800">
                <button
                    type="button"
                    className="w-full py-2.5 px-4 rounded-xl text-sm font-medium text-zinc-400 hover:bg-zinc-800 transition-colors duration-200"
                >
                    Ver Todas as Transações
                </button>
            </div>
        </div>
    );
};

export default MinhasCompras;
