import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import clsx from "clsx";
import { jwtDecode } from "jwt-decode";
import axiosInstance, { setAuthToken, setCsrfToken } from "./axiosConfig";
import DOMPurify from "dompurify";
import MinhasCompras from "./MinhasCompras";
import {
    Menu as MenuIcon,
    X,
    ShoppingCart,
    CreditCard,
    User,
    Lock,
    Calendar,
    Trash2,
    Landmark,
    CheckCircle,
    Share2,
    Zap,
    Sparkles,
    ArrowLeft,
    DollarSign,
    XCircle,
    Loader2,
    AlertTriangle,
    Award,
} from "lucide-react";
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
} from "react-icons/fa";
import AstraShopLogin from "./AstraShopLogin";
import Menu from "./Menu";
import FinalizarCompra from "./FinalizarCompra";
import AdvancedSearch from "./AdvancedSearch";
import UserSettings from "./UserSettings";
import PaymentMethods from "./PaymentMethods";
import CardTableView from "./CardTableView";

// Import react-toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Button Component
const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground bg-transparent":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2": size === "default",
                "h-9 rounded-md px-3": size === "sm",
                "h-11 rounded-md px-8": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

// Input Component
const Input = React.forwardRef(
    ({ className, type = "text", ...props }, ref) => {
        const classes = clsx(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
        );
        return <input type={type} className={classes} ref={ref} {...props} />;
    },
);
Input.displayName = "Input";

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-lg border bg-card text-card-foreground shadow-sm transition-colors duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex flex-col space-y-1.5 p-4", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={clsx(
            "text-base font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={clsx("p-4 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex items-center p-4 pt-0", className)}
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
            onClick={handleOverlayClick}
        >
            <div className="relative bg-background rounded-lg shadow-lg w-full max-w-lg p-6 overflow-auto max-h-full">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                {children}
            </div>
        </div>
    );
};

// Popover Components
const Popover = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return React.Children.map(children, (child) =>
        React.cloneElement(child, { isOpen, setIsOpen }),
    );
};

const PopoverTrigger = ({ children, isOpen, setIsOpen }) => {
    return React.cloneElement(children, {
        onClick: () => setIsOpen(!isOpen),
    });
};

// PopoverContent Component
const PopoverContent = ({ children, className, isOpen, ...props }) => {
    if (!isOpen) return null;

    return (
        <div
            className={clsx(
                "absolute z-50 mt-2",
                "left-0 transform-none",
                "lg:left-auto lg:right-0",
                // Definir largura responsiva:
                // w-64: 16rem (256px) para telas pequenas
                // sm:w-80: 20rem (320px) para telas pequenas/médias (≥640px)
                // md:w-96: 24rem (384px) para telas médias e maiores (≥768px)
                "w-64 sm:w-80 md:w-96",
                "p-4 bg-popover text-popover-foreground border rounded-md shadow-md outline-none",
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};

// Function to get brand icon
const getBrandIcon = (brand) => {
    switch (brand.toLowerCase()) {
        case "visa":
            return <FaCcVisa className="h-6 w-6" />;
        case "mastercard":
            return <FaCcMastercard className="h-6 w-6" />;
        case "american express":
        case "amex":
            return <FaCcAmex className="h-6 w-6" />;
        case "discover":
            return <FaCcDiscover className="h-6 w-6" />;
        default:
            return <CreditCard className="h-6 w-6" />;
    }
};

// Function to get unique card identifier
const getCardId = (card) => card._id || card.id;

// Updated CardInterface Component
const CardInterface = ({
    currentPage,
    setCurrentPage,
    onLogout,
    username,
    Button,
    Input,
    purchaseMode,
    updatePurchaseMode,
    userBalance,
    setUserBalance,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [cart, setCart] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuLayout, setMenuLayout] = useState(
        localStorage.getItem("menuLayout") || "vertical",
    );

    const [searchQuery, setSearchQuery] = useState("");
    const [filters, setFilters] = useState([]);

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Estados para Smart Buy
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showError, setShowError] = useState(false);
    const [purchaseStatus, setPurchaseStatus] = useState("idle");
    const [isSmartBuyModalOpen, setIsSmartBuyModalOpen] = useState(false);
    const [similarCards, setSimilarCards] = useState([]);

    // Estados para Quick Buy
    const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false);
    const [cardToBuy, setCardToBuy] = useState(null);
    const [isProcessingQuickBuy, setIsProcessingQuickBuy] = useState(false);
    const [quickBuyError, setQuickBuyError] = useState(null);
    const [quickBuySuccess, setQuickBuySuccess] = useState(false);

    const [visualizationMode, setVisualizationMode] = useState(null);

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === "menuLayout") {
                setMenuLayout(e.newValue || "vertical");
            }
        };

        // Adiciona o listener para mudanças no storage
        window.addEventListener("storage", handleStorageChange);

        // Também monitora mudanças diretas no localStorage
        const checkLayoutInterval = setInterval(() => {
            const currentLayout =
                localStorage.getItem("menuLayout") || "vertical";
            if (currentLayout !== menuLayout) {
                setMenuLayout(currentLayout);
            }
        }, 100);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
            clearInterval(checkLayoutInterval);
        };
    }, [menuLayout]);

    const fetchVisualizationMode = async () => {
        try {
            const response = await axiosInstance.get("/user-settings");
            setVisualizationMode(
                response.data.settings.visualizationMode || "card",
            );
        } catch (error) {
            console.error("Erro ao buscar modo de visualização:", error);
            // Em caso de erro, usar card como fallback
            setVisualizationMode("card");
        }
    };

    // Usar useEffect para buscar o modo de visualização
    useEffect(() => {
        fetchVisualizationMode();

        // Configurar um intervalo para buscar atualizações periodicamente
        const interval = setInterval(fetchVisualizationMode, 5000); // A cada 5 segundos

        return () => clearInterval(interval);
    }, []);

    // Função para remover o cartão
    const removeCard = (cardId) => {
        setCards((prevCards) =>
            prevCards.filter((card) => getCardId(card) !== cardId),
        );
    };

    // Fetch cart from server when component mounts
    useEffect(() => {
        if (purchaseMode === "cart") {
            fetchCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseMode]);

    const normalizeCart = (cartArray) => {
        return cartArray
            .map((item) => {
                if (typeof item === "string") {
                    return item;
                } else if (item._id) {
                    return item._id;
                } else {
                    return null;
                }
            })
            .filter(Boolean);
    };

    const resetSmartBuyStates = () => {
        setIsSmartBuyModalOpen(false);
        setSimilarCards([]);
        setCurrentStep(0);
        setCurrentCardIndex(0);
        setPurchaseStatus("idle");
        setShowError(false);
        setCardToBuy(null);
        setIsProcessing(false);
    };

    const fetchCart = async () => {
        try {
            const response = await axiosInstance.get("/cart");
            const cartFromServer = response.data.cart;
            const cartIds = normalizeCart(cartFromServer);
            setCart(cartIds);
            localStorage.setItem("cart", JSON.stringify(cartIds));
        } catch (error) {
            console.error("Erro ao buscar o carrinho:", error);
            toast.error(
                "Erro ao carregar o carrinho. Tente novamente mais tarde.",
            );
        }
    };

    const fetchCards = async (pageNumber = 1) => {
        setLoading(true);
        setError(null);
        try {
            const params = new URLSearchParams();
            params.append("page", pageNumber);
            params.append("limit", 100);

            if (searchQuery) {
                params.append("search", searchQuery);
            }
            if (filters.length > 0) {
                const validFilters = filters.filter((filter) => filter.value);
                if (validFilters.length > 0) {
                    params.append("filters", JSON.stringify(validFilters));
                }
            }

            const response = await axiosInstance.get(
                `/get-cards?${params.toString()}`,
            );
            const fetchedCards = response.data?.cards || [];

            // Se recebemos 100 cards (nosso limite), assumimos que há mais páginas
            const hasMorePages = fetchedCards.length >= 100;

            setCards((prevCards) =>
                pageNumber === 1
                    ? fetchedCards
                    : [...prevCards, ...fetchedCards],
            );

            setPage(pageNumber);
            setTotalPages(hasMorePages ? pageNumber + 1 : pageNumber);
        } catch (err) {
            console.error("Erro ao buscar cartões:", err);
            setError("Falha ao carregar os cartões.");
            setCards([]);
            setPage(1);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    // Fetch cards when search or filters change
    useEffect(() => {
        setPage(1);
        fetchCards(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, filters]);

    useEffect(() => {
        fetchCards(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadMore = () => {
        if (page < totalPages && !loading) {
            fetchCards(page + 1);
        }
    };

    // Função atualizada addToCart
    const addToCart = async (card) => {
        try {
            // Obter o carrinho mais recente do servidor
            const response = await axiosInstance.get("/cart");
            const serverCart = normalizeCart(response.data.cart);

            // Atualizar o estado local do carrinho
            setCart(serverCart);
            localStorage.setItem("cart", JSON.stringify(serverCart));

            const cardId = getCardId(card);

            // Verificar se o cartão já está no carrinho
            if (serverCart.includes(cardId)) {
                toast.error(`O item com BIN ${card.bin} já está no carrinho.`);
                return;
            }

            // Criar um novo carrinho com o novo cartão
            const newCart = [...serverCart, cardId];

            // Enviar o novo carrinho para o servidor
            const postResponse = await axiosInstance.post("/cart", {
                cart: newCart,
            });

            if (postResponse.status >= 200 && postResponse.status < 300) {
                // Operação bem-sucedida
                // Atualizar o carrinho com a resposta do servidor
                const updatedCart = normalizeCart(
                    postResponse.data.cart || newCart,
                );
                setCart(updatedCart);
                localStorage.setItem("cart", JSON.stringify(updatedCart));

                toast.success(
                    `Item com BIN ${card.bin} adicionado ao carrinho!`,
                );
            } else {
                // Tratar erro do servidor
                const errorMessage =
                    postResponse.data.error || "Erro desconhecido.";
                toast.error(`Erro: ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro ao atualizar o carrinho:", error);
            toast.error(
                "Erro ao adicionar o item ao carrinho. Tente novamente mais tarde.",
            );
        }
    };

    // Função atualizada removeFromCart
    const removeFromCart = async (cardId) => {
        try {
            // Obter o carrinho mais recente do servidor
            const response = await axiosInstance.get("/cart");
            const serverCart = normalizeCart(response.data.cart);

            // Atualizar o estado local do carrinho
            setCart(serverCart);
            localStorage.setItem("cart", JSON.stringify(serverCart));

            // Criar um novo carrinho sem o cartão a ser removido
            const newCart = serverCart.filter((id) => id !== cardId);

            // Enviar o novo carrinho para o servidor
            const postResponse = await axiosInstance.post("/cart", {
                cart: newCart,
            });

            if (postResponse.status >= 200 && postResponse.status < 300) {
                // Operação bem-sucedida
                // Atualizar o carrinho com a resposta do servidor
                const updatedCart = normalizeCart(
                    postResponse.data.cart || newCart,
                );
                setCart(updatedCart);
                localStorage.setItem("cart", JSON.stringify(updatedCart));

                toast.info("Item removido do carrinho.");
            } else {
                // Tratar erro do servidor
                const errorMessage =
                    postResponse.data.error || "Erro desconhecido.";
                toast.error(`Erro: ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro ao atualizar o carrinho:", error);
            toast.error("Erro ao remover o item do carrinho. Tente novamente.");
        }
    };

    const updateCartOnServer = async (newCart) => {
        try {
            await axiosInstance.post("/cart", { cart: newCart });
        } catch (error) {
            console.error("Erro ao atualizar o carrinho:", error);
        }
    };

    const openModal = (card) => {
        setSelectedCard(card);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCard(null);
        setIsModalOpen(false);
    };

    const findSimilarCards = async (originalCard) => {
        // Criar filtros baseados no cartão original
        const similarFilters = [
            // Filtro por BIN
            {
                type: "bin",
                value: originalCard.bin,
            },
            // Filtro por nível
            {
                type: "level",
                value: originalCard.level,
            },
            // Filtro por validade
            {
                type: "expiry",
                value: `${originalCard.month}/${originalCard.year}`,
            },
        ];

        try {
            const params = new URLSearchParams();
            params.append("filters", JSON.stringify(similarFilters));

            const response = await axiosInstance.get(
                `/get-cards?${params.toString()}`,
            );
            return response.data.cards.filter(
                (card) => card._id !== originalCard._id,
            );
        } catch (error) {
            console.error("Erro ao buscar cartões similares:", error);
            return [];
        }
    };

    const handleSmartBuy = async (card) => {
        try {
            // Reseta os estados antes de iniciar uma nova compra
            resetSmartBuyStates();

            // Buscar cartões similares antes de abrir o modal
            const similarFilters = [
                { type: "bin", value: card.bin },
                { type: "level", value: card.level },
                { type: "expiry", value: `${card.month}/${card.year}` },
            ];

            const params = new URLSearchParams();
            params.append("filters", JSON.stringify(similarFilters));

            const response = await axiosInstance.get(
                `/get-cards?${params.toString()}`,
            );
            const foundSimilarCards = response.data.cards
                .filter((c) => c._id !== card._id)
                .slice(0, 3); // Limita a 4 cartões similares

            // Armazena os cartões similares encontrados
            console.log("Cartões similares encontrados:", foundSimilarCards);
            setSimilarCards(foundSimilarCards);

            // Define o cartão atual e abre o modal
            setCardToBuy(card);
            setIsSmartBuyModalOpen(true);
        } catch (error) {
            console.error("Erro ao buscar cartões similares:", error);
            toast.error("Erro ao buscar cartões similares");
        }
    };
    const processSmartBuy = async (originalCard) => {
        // Reset inicial dos estados
        setIsProcessing(true);
        setPurchaseStatus("idle");
        setShowError(false);
        setCurrentStep(0);
        setCurrentCardIndex(0);

        // Array com todos os cartões a serem tentados
        const allCards = [originalCard, ...similarCards];

        // Tentar cada cartão em sequência
        for (let i = 0; i < allCards.length; i++) {
            setCurrentCardIndex(i);
            setCurrentStep(i);
            setPurchaseStatus("processing");
            setShowError(false);

            try {
                const response = await axiosInstance.post("/purchase", {
                    cards: [allCards[i]._id],
                });

                if (response.data.purchased_cards.length > 0) {
                    // Atualizar saldo
                    setUserBalance(response.data.new_balance);
                    localStorage.setItem(
                        "userBalance",
                        response.data.new_balance.toString(),
                    );

                    // Atualizar status
                    setPurchaseStatus("success");

                    // Remover o cartão da lista
                    setCards((prevCards) =>
                        prevCards.filter((c) => c._id !== allCards[i]._id),
                    );

                    // Mostrar notificação de sucesso
                    toast.success("Compra realizada com sucesso!");

                    // Aguardar um momento antes de fechar o modal e redirecionar
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    setIsSmartBuyModalOpen(false);
                    setCurrentPage("minhas-compras");
                    break;
                } else {
                    setPurchaseStatus("error");
                    setShowError(true);

                    if (response.data.failed_cards?.[0]?.error) {
                        toast.error(
                            `Falha na tentativa: ${response.data.failed_cards[0].error}`,
                        );
                    }

                    // Aguardar antes de tentar o próximo cartão
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                }
            } catch (error) {
                setPurchaseStatus("error");
                setShowError(true);
                toast.error(
                    error.response?.data?.error || "Erro ao processar a compra",
                );
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
        }

        setIsProcessing(false);
        setIsSmartBuyModalOpen(false);
        setPurchaseStatus("idle");
    };

    const handleQuickBuy = (card) => {
        setCardToBuy(card);
        setIsQuickBuyModalOpen(true);
        setQuickBuyError(null);
        setQuickBuySuccess(false);
    };

    const confirmQuickBuy = async () => {
        setIsProcessingQuickBuy(true);
        setQuickBuyError(null);
        setQuickBuySuccess(false);
        try {
            const response = await axiosInstance.post("/purchase", {
                cards: [cardToBuy._id], // Enviando apenas o ID do cartão
            });

            if (response.status === 200) {
                const { new_balance, purchased_cards, failed_cards } =
                    response.data;

                // Verifica se o cartão comprado está na lista de cartões comprados
                if (purchased_cards.includes(cardToBuy._id)) {
                    // Compra bem-sucedida
                    setUserBalance(new_balance);
                    localStorage.setItem("userBalance", new_balance.toString());
                    toast.success("Compra realizada com sucesso!");
                    setQuickBuySuccess(true);

                    // Remover o cartão comprado da lista de cartões
                    setCards((prevCards) =>
                        prevCards.filter((card) => card._id !== cardToBuy._id),
                    );

                    // Fechar o modal e redirecionar para minhas compras
                    setIsQuickBuyModalOpen(false);
                    setCardToBuy(null);
                    setCurrentPage("minhas-compras");
                }

                // Verifica se o cartão falhou na compra
                if (
                    failed_cards.some((card) => card.card_id === cardToBuy._id)
                ) {
                    const failedCard = failed_cards.find(
                        (card) => card.card_id === cardToBuy._id,
                    );
                    const errorMessage = failedCard
                        ? failedCard.error
                        : "Erro desconhecido";

                    setQuickBuyError(
                        `Erro ao finalizar compra: ${errorMessage}`,
                    );
                    toast.error(`Erro ao finalizar compra: ${errorMessage}`);

                    // Remover o cartão falhado da lista de cartões
                    setCards((prevCards) =>
                        prevCards.filter((card) => card._id !== cardToBuy._id),
                    );
                }
            } else {
                // Tratamento para respostas com status diferente de 200
                const { error } = response.data;
                setQuickBuyError(
                    `Erro ao finalizar compra: ${error || "Erro desconhecido"}`,
                );
                toast.error(
                    `Erro ao finalizar compra: ${error || "Erro desconhecido"}`,
                );

                // Remover o cartão da lista de cartões devido a erro genérico
                setCards((prevCards) =>
                    prevCards.filter((card) => card._id !== cardToBuy._id),
                );
            }
        } catch (error) {
            console.error("Erro ao processar a compra:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                setQuickBuyError(
                    `Erro ao finalizar compra: ${error.response.data.error}`,
                );
                toast.error(
                    `Erro ao finalizar compra: ${error.response.data.error}`,
                );
            } else {
                setQuickBuyError("Ocorreu um erro ao processar sua compra.");
                toast.error("Ocorreu um erro ao processar sua compra.");
            }

            // Remover o cartão da lista de cartões devido a erro de rede ou outro
            setCards((prevCards) =>
                prevCards.filter((card) => card._id !== cardToBuy._id),
            );
        } finally {
            setIsProcessingQuickBuy(false);
            if (quickBuySuccess) {
                setIsQuickBuyModalOpen(false);
                setCardToBuy(null);
            }
        }
    };

    const shareCardLink = (card) => {
        const cardId = getCardId(card);
        const link = `${window.location.origin}/?card=${cardId}`;
        navigator.clipboard
            .writeText(link)
            .then(() => {
                toast.success("Link copiado para a área de transferência!");
            })
            .catch((err) => {
                console.error("Falha ao copiar o link: ", err);
            });
    };

    const handleAddBalance = () => {
        setCurrentPage("add-balance");
    };

    // Formatação do saldo
    const formattedBalance = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(userBalance || 0);

    // Sync cart and balance across windows
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "cart") {
                const newCart = JSON.parse(event.newValue) || [];
                setCart(newCart);
            }
            if (event.key === "userBalance") {
                const newBalance = parseFloat(event.newValue) || 0;
                setUserBalance(newBalance);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <div className="flex flex-col h-screen bg-background text-foreground">
            {/* Header for small screens */}
            <div
                className={clsx(
                    "flex items-center justify-between p-4 lg:hidden",
                    menuLayout === "horizontal" && "hidden", // Esconde quando o menu é horizontal
                )}
            >
                <Button
                    variant="outline"
                    size="icon"
                    onClick={() => setMenuOpen(true)}
                >
                    <MenuIcon className="h-6 w-6" />
                </Button>
            </div>

            <div
                className={clsx(
                    "flex flex-1 overflow-hidden",
                    menuLayout === "horizontal" && "mt-16", // Mantém mt-16 para o menu horizontal
                )}
            >
                {/* Menu component */}
                <Menu
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    setCurrentPage={setCurrentPage}
                    onLogout={onLogout}
                    username={username}
                />

                {/* Main content */}
                <div className="flex-1 overflow-y-auto p-4">
                    {currentPage === "settings" ? (
                        <div className="flex flex-col items-center">
                            <UserSettings
                                onClose={() => setCurrentPage("main")}
                                updatePurchaseMode={updatePurchaseMode}
                                currentMode={purchaseMode}
                            />
                        </div>
                    ) : currentPage === "minhas-compras" ? (
                        <div className="container mx-auto px-2 sm:px-4">
                            <MinhasCompras />
                        </div>
                    ) : currentPage === "finalizar-compra" ? (
                        <div className="container mx-auto px-2 sm:px-4">
                            {/* Back button */}
                            <div className="mb-4">
                                <Button
                                    variant="ghost"
                                    onClick={() => setCurrentPage("main")}
                                    className="flex items-center"
                                >
                                    <ArrowLeft className="h-5 w-5 mr-2" />
                                    Voltar
                                </Button>
                            </div>
                            <FinalizarCompra
                                cart={cart}
                                setCart={setCart}
                                onClose={() => setCurrentPage("main")}
                                username={username}
                                userBalance={userBalance}
                                setUserBalance={setUserBalance}
                                updateCartOnServer={updateCartOnServer}
                                removeCard={removeCard}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) : currentPage === "add-balance" ? (
                        <PaymentMethods setCurrentPage={setCurrentPage} />
                    ) : (
                        // Main content goes here
                        <div className="container mx-auto px-2 sm:px-4">
                            {/* User greeting */}
                            {username && (
                                <div className="mb-4">
                                    <p className="text-lg font-semibold">
                                        Bem-vindo, {username}!
                                    </p>
                                </div>
                            )}

                            {/* Search box and icons */}
                            <div className="mb-6 flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
                                <div className="w-full lg:w-2/3">
                                    <AdvancedSearch
                                        onSearchChange={setSearchQuery}
                                        onFiltersChange={setFilters}
                                        Button={Button}
                                        Input={Input}
                                        purchaseMode={purchaseMode}
                                        cart={cart}
                                        cards={cards}
                                        removeFromCart={removeFromCart}
                                        getCardId={getCardId}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                                <div className="flex gap-2 items-center">
                                    <div className="flex items-center bg-secondary rounded-md overflow-hidden">
                                        <span className="hidden sm:inline-block px-4 py-2 font-semibold text-secondary-foreground">
                                            Saldo: {formattedBalance}
                                        </span>
                                        <Button
                                            onClick={handleAddBalance}
                                            className="p-2 bg-accent hover:bg-accent-foreground text-accent-foreground hover:text-accent transition-colors rounded-md sm:rounded-l-none"
                                            title={`Saldo: ${formattedBalance}`}
                                        >
                                            <DollarSign className="h-5 w-5" />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {/* Loading or error feedback */}
                            {loading && (
                                <p className="text-center">
                                    Carregando cartões...
                                </p>
                            )}
                            {error && (
                                <p className="text-center text-red-500">
                                    {error}
                                </p>
                            )}

                            {/* Card list */}
                            {visualizationMode === "table" ? (
                                <CardTableView
                                    cards={cards}
                                    purchaseMode={purchaseMode}
                                    onShare={shareCardLink}
                                    onAddToCart={addToCart}
                                    onQuickBuy={handleQuickBuy}
                                    onSmartBuy={handleSmartBuy}
                                    getBrandIcon={getBrandIcon}
                                    openModal={openModal}
                                />
                            ) : (
                                <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                                    {cards?.length > 0
                                        ? cards.map((card, index) => (
                                              <Card
                                                  key={index}
                                                  className="bg-card text-card-foreground cursor-pointer w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                                                  onClick={() =>
                                                      openModal(card)
                                                  }
                                              >
                                                  {/* Brand icon as background */}
                                                  <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                                                      <div className="text-[30rem] transform scale-150 text-gray-300 opacity-10 pointer-events-none">
                                                          {getBrandIcon(
                                                              card.vendor,
                                                          )}
                                                      </div>
                                                  </div>

                                                  {/* Card content */}
                                                  <div className="relative z-10 p-4 sm:p-6">
                                                      <CardHeader className="p-0">
                                                          <CardTitle className="flex items-center justify-between text-base sm:text-lg">
                                                              <span className="flex items-center">
                                                                  {getBrandIcon(
                                                                      card.vendor,
                                                                  )}
                                                                  <span className="ml-2 font-semibold">
                                                                      BIN:{" "}
                                                                      {card.bin}
                                                                  </span>
                                                              </span>
                                                              <span className="text-sm md:text-base font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full">
                                                                  {card.level}
                                                              </span>
                                                          </CardTitle>
                                                      </CardHeader>
                                                      <CardContent className="mt-4 p-0">
                                                          <p className="flex items-center mb-2 text-sm">
                                                              <User className="h-5 w-5 mr-2 flex-shrink-0" />
                                                              <span className="truncate">
                                                                  Nome:{" "}
                                                                  {card.nome}
                                                              </span>
                                                          </p>
                                                          <p className="flex items-center mb-2 text-sm">
                                                              <Lock className="h-5 w-5 mr-2 flex-shrink-0" />
                                                              <span className="truncate">
                                                                  CPF:{" "}
                                                                  {card.cpf}
                                                              </span>
                                                          </p>
                                                          <p className="flex items-center mb-2 text-sm">
                                                              <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                                                              <span>
                                                                  Validade:{" "}
                                                                  {card.month}/
                                                                  {card.year}
                                                              </span>
                                                          </p>
                                                          <p className="flex items-center mb-2 text-sm">
                                                              <Landmark className="h-5 w-5 mr-2 flex-shrink-0" />
                                                              <span className="truncate">
                                                                  Banco:{" "}
                                                                  {card.bank}
                                                              </span>
                                                          </p>
                                                          <p className="flex items-center mb-2 text-sm">
                                                              <DollarSign className="h-5 w-5 mr-2 flex-shrink-0" />
                                                              <span className="truncate">
                                                                  Preço: R${" "}
                                                                  {parseFloat(
                                                                      card.price,
                                                                  ).toFixed(2)}
                                                              </span>
                                                          </p>
                                                      </CardContent>

                                                      <CardFooter className="flex justify-between items-center p-0">
                                                          <Button
                                                              variant="ghost"
                                                              size="icon"
                                                              onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  shareCardLink(
                                                                      card,
                                                                  );
                                                              }}
                                                              className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                                          >
                                                              <Share2 className="h-5 w-5" />
                                                              <span className="sr-only">
                                                                  Compartilhar
                                                                  Link
                                                              </span>
                                                          </Button>
                                                          {purchaseMode ===
                                                          "cart" ? (
                                                              <Button
                                                                  size="icon"
                                                                  className="h-10 w-10 bg-customGreen text-customGreen-foreground rounded-full flex items-center justify-center transition-transform duration-200 hover:bg-customGreen-hover hover:scale-110"
                                                                  onClick={(
                                                                      e,
                                                                  ) => {
                                                                      e.stopPropagation();
                                                                      console.log(
                                                                          "Modo de compra:",
                                                                          purchaseMode,
                                                                      );
                                                                      addToCart(
                                                                          card,
                                                                      );
                                                                  }}
                                                              >
                                                                  <ShoppingCart className="h-5 w-5 animate-pulse" />
                                                                  <span className="sr-only">
                                                                      Adicionar
                                                                      ao
                                                                      carrinho
                                                                  </span>
                                                              </Button>
                                                          ) : purchaseMode ===
                                                            "smart" ? (
                                                              <Button
                                                                  size="icon"
                                                                  variant="ghost"
                                                                  onClick={(
                                                                      e,
                                                                  ) => {
                                                                      e.stopPropagation();
                                                                      console.log(
                                                                          "Modo de compra:",
                                                                          purchaseMode,
                                                                      );
                                                                      handleSmartBuy(
                                                                          card,
                                                                      );
                                                                  }}
                                                                  className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors bg-blue-50"
                                                              >
                                                                  <Sparkles className="h-5 w-5 text-blue-500" />
                                                                  <span className="sr-only">
                                                                      Compra
                                                                      Inteligente
                                                                  </span>
                                                              </Button>
                                                          ) : (
                                                              <Button
                                                                  size="icon"
                                                                  variant="ghost"
                                                                  onClick={(
                                                                      e,
                                                                  ) => {
                                                                      e.stopPropagation();
                                                                      console.log(
                                                                          "Modo de compra:",
                                                                          purchaseMode,
                                                                      );
                                                                      handleQuickBuy(
                                                                          card,
                                                                      );
                                                                  }}
                                                                  className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                                              >
                                                                  <Zap className="h-5 w-5" />
                                                                  <span className="sr-only">
                                                                      Compra
                                                                      Instantânea
                                                                  </span>
                                                              </Button>
                                                          )}
                                                      </CardFooter>
                                                  </div>
                                              </Card>
                                          ))
                                        : !loading &&
                                          !error && (
                                              <p className="col-span-full text-center">
                                                  Nenhum cartão encontrado.
                                              </p>
                                          )}
                                </div>
                            )}

                            {/* Load More Button */}
                            {/* Load More Button */}
                            {!loading && cards?.length > 0 && (
                                <div className="flex justify-center mt-6">
                                    <Button onClick={handleLoadMore}>
                                        Carregar Mais
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Card details modal */}
            {selectedCard && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <h2 className="text-2xl font-bold mb-4">
                        Detalhes do Cartão
                    </h2>
                    <p className="mb-2">
                        <strong>BIN:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bin)}
                    </p>
                    <p className="mb-2">
                        <strong>Validade:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.month)}/
                        {DOMPurify.sanitize(selectedCard.year)}
                    </p>
                    <p className="mb-2">
                        <strong>Vendor:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.vendor)}
                    </p>
                    <p className="mb-2">
                        <strong>Tipo:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.type)}
                    </p>
                    <p className="mb-2">
                        <strong>Nível:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.level)}
                    </p>
                    <p className="mb-2">
                        <strong>Banco:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bank)}
                    </p>
                    <p className="mb-2">
                        <strong>País:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.country)}
                    </p>
                    <p className="mb-2">
                        <strong>Nome:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.nome)}
                    </p>
                    <p className="mb-2">
                        <strong>CPF:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.cpf)}
                    </p>
                </Modal>
            )}

            {/* Quick Buy Modal */}
            {isQuickBuyModalOpen && cardToBuy && (
                <Modal
                    isOpen={isQuickBuyModalOpen}
                    onClose={() => {
                        if (!isProcessingQuickBuy) {
                            setIsQuickBuyModalOpen(false);
                            setCardToBuy(null);
                            setQuickBuyError(null);
                            setQuickBuySuccess(false);
                        }
                    }}
                >
                    {!quickBuySuccess && !quickBuyError && (
                        <>
                            <h2 className="text-2xl font-bold mb-4">
                                Confirmar Compra
                            </h2>
                            <p className="mb-2">
                                Você tem certeza que deseja comprar o seguinte
                                cartão?
                            </p>
                            {/* Detalhes do Cartão */}
                            <div className="mb-2">
                                <strong>BIN:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.bin)}
                            </div>
                            <div className="mb-2">
                                <strong>Validade:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.month)}/
                                {DOMPurify.sanitize(cardToBuy.year)}
                            </div>
                            <div className="mb-2">
                                <strong>Vendor:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.vendor)}
                            </div>
                            <div className="mb-2">
                                <strong>Tipo:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.type)}
                            </div>
                            <div className="mb-2">
                                <strong>Nível:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.level)}
                            </div>
                            <div className="mb-2">
                                <strong>Banco:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.bank)}
                            </div>
                            <div className="mb-2">
                                <strong>País:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.country)}
                            </div>
                            <div className="mb-2">
                                <strong>Nome:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.nome)}
                            </div>
                            <div className="mb-2">
                                <strong>CPF:</strong>{" "}
                                {DOMPurify.sanitize(cardToBuy.cpf)}
                            </div>
                            <div className="mb-2">
                                <strong>Preço:</strong> R${" "}
                                {parseFloat(cardToBuy.price).toFixed(2)}
                            </div>
                            {isProcessingQuickBuy && (
                                <p className="mt-4 text-blue-600">
                                    Processando compra...
                                </p>
                            )}
                            {quickBuyError && (
                                <p className="mt-4 text-red-600">
                                    {quickBuyError}
                                </p>
                            )}
                            <div className="flex justify-end mt-4">
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        if (!isProcessingQuickBuy) {
                                            setIsQuickBuyModalOpen(false);
                                            setCardToBuy(null);
                                            setQuickBuyError(null);
                                            setQuickBuySuccess(false);
                                        }
                                    }}
                                    className="mr-2"
                                    disabled={isProcessingQuickBuy}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="default"
                                    onClick={confirmQuickBuy}
                                    disabled={isProcessingQuickBuy}
                                >
                                    Confirmar Compra
                                </Button>
                            </div>
                        </>
                    )}
                    {quickBuySuccess && (
                        <>
                            <h2 className="text-2xl font-bold mb-4">
                                Compra Realizada
                            </h2>
                            <p className="mb-2">
                                A compra do cartão com BIN{" "}
                                {DOMPurify.sanitize(cardToBuy.bin)} foi
                                realizada com sucesso!
                            </p>
                            <div className="flex justify-end mt-4">
                                <Button
                                    variant="default"
                                    onClick={() => {
                                        setIsQuickBuyModalOpen(false);
                                        setCardToBuy(null);
                                        setQuickBuyError(null);
                                        setQuickBuySuccess(false);
                                    }}
                                >
                                    Fechar
                                </Button>
                            </div>
                        </>
                    )}
                    {quickBuyError && (
                        <>
                            <h2 className="text-2xl font-bold mb-4">
                                Erro na Compra
                            </h2>
                            <p className="mb-2">{quickBuyError}</p>
                            {/* Detalhes do Cartão com Erro */}
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold mb-2">
                                    Detalhes do Cartão com Erro:
                                </h3>
                                <div className="mb-2">
                                    <strong>BIN:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.bin)}
                                </div>
                                <div className="mb-2">
                                    <strong>Validade:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.month)}/
                                    {DOMPurify.sanitize(cardToBuy.year)}
                                </div>
                                <div className="mb-2">
                                    <strong>Vendor:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.vendor)}
                                </div>
                                <div className="mb-2">
                                    <strong>Tipo:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.type)}
                                </div>
                                <div className="mb-2">
                                    <strong>Nível:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.level)}
                                </div>
                                <div className="mb-2">
                                    <strong>Banco:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.bank)}
                                </div>
                                <div className="mb-2">
                                    <strong>País:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.country)}
                                </div>
                                <div className="mb-2">
                                    <strong>Nome:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.nome)}
                                </div>
                                <div className="mb-2">
                                    <strong>CPF:</strong>{" "}
                                    {DOMPurify.sanitize(cardToBuy.cpf)}
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        setIsQuickBuyModalOpen(false);
                                        setCardToBuy(null);
                                        setQuickBuyError(null);
                                        setQuickBuySuccess(false);
                                    }}
                                    className="mr-2"
                                    disabled={isProcessingQuickBuy}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </>
                    )}
                </Modal>
            )}

            <SmartBuyModal
                isOpen={isSmartBuyModalOpen}
                onClose={resetSmartBuyStates} // Aqui usamos a nova função
                originalCard={cardToBuy}
                similarCards={similarCards}
                currentStep={currentStep}
                currentCardIndex={currentCardIndex}
                purchaseStatus={purchaseStatus}
                showError={showError}
                onConfirm={processSmartBuy}
            />
        </div>
    );
};

const SmartBuyModal = ({
    isOpen,
    onClose,
    originalCard,
    similarCards = [],
    currentStep,
    currentCardIndex,
    purchaseStatus,
    showError,
    onConfirm,
}) => {
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setHasConfirmed(false);
            setIsProcessing(false);
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            setHasConfirmed(false);
            setIsProcessing(false);
        };
    }, []);

    const allCards = originalCard
        ? [originalCard, ...(similarCards || [])]
        : [];
    const currentCard = allCards[currentCardIndex];
    const isLastAttempt = currentStep === allCards.length - 1;
    const allAttemptsFailed = isLastAttempt && purchaseStatus === "error";

    const handleConfirm = () => {
        setHasConfirmed(true);
        setIsProcessing(true);
        onConfirm(originalCard);
    };

    const handleClose = () => {
        if (!isProcessing) {
            setHasConfirmed(false);
            setIsProcessing(false);
            onClose();
        }
    };

    if (!isOpen || !originalCard) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-3 md:p-4">
            <div className="fixed inset-0 bg-black/80" onClick={handleClose} />
            <div className="relative bg-card w-full max-w-lg rounded-lg overflow-y-auto max-h-[90vh] md:max-h-[85vh]">
                {!hasConfirmed ? (
                    <div className="flex flex-col">
                        <div className="bg-background p-4 md:p-6 flex items-start justify-between sticky top-0 z-10">
                            <div className="flex items-center gap-2 md:gap-3">
                                <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-primary" />
                                <div>
                                    <h2 className="text-lg md:text-xl font-semibold text-card-foreground">
                                        Sistema Smart Buy
                                    </h2>
                                    <p className="text-xs md:text-sm text-muted-foreground">
                                        Sistema avançado de compra inteligente
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={handleClose}
                                className="text-muted-foreground hover:text-card-foreground"
                            >
                                <X className="w-5 h-5" />
                            </button>
                        </div>

                        <div className="p-4 md:p-6 space-y-4 md:space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className="bg-background rounded-lg p-3 md:p-4">
                                    <div className="flex gap-2 md:gap-3 items-start">
                                        <Sparkles className="w-5 h-5 text-primary shrink-0" />
                                        <div>
                                            <h3 className="font-medium text-sm md:text-base text-card-foreground">
                                                Processo Inteligente
                                            </h3>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Checagem automática de até{" "}
                                                {similarCards.length} cartões
                                                similares
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-background rounded-lg p-3 md:p-4">
                                    <div className="flex gap-2 md:gap-3 items-start">
                                        <CheckCircle className="w-5 h-5 text-primary shrink-0" />
                                        <div>
                                            <h3 className="font-medium text-sm md:text-base text-card-foreground">
                                                Garantia de Eficiência
                                            </h3>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Processo otimizado com pausa
                                                automática
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-background rounded-lg p-3 md:p-4">
                                <h3 className="text-xs md:text-sm font-medium text-muted-foreground mb-3 md:mb-4">
                                    Cartão Principal
                                </h3>
                                <div className="grid grid-cols-2 gap-x-4 md:gap-x-12 gap-y-3 md:gap-y-4">
                                    <div className="flex items-center gap-2">
                                        <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                BIN
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.bin}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Calendar className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Validade
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.month}/
                                                {originalCard.year}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Award className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Nível
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.level}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Landmark className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Banco
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground truncate max-w-[120px] md:max-w-[180px]">
                                                {originalCard.bank}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {similarCards?.length > 0 && (
                                <div className="bg-background rounded-lg p-3 md:p-4 flex items-start gap-2 md:gap-3">
                                    <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 text-primary shrink-0" />
                                    <div>
                                        <h4 className="text-sm md:text-base font-medium text-card-foreground">
                                            {similarCards.length} Cartões
                                            Alternativos
                                        </h4>
                                        <p className="text-xs md:text-sm text-muted-foreground mt-1">
                                            Cartões similares serão testados
                                            automaticamente
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-end gap-2 md:gap-3 pt-4 border-t border-border">
                                <Button
                                    variant="ghost"
                                    onClick={handleClose}
                                    className="text-sm"
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={handleConfirm}
                                    className="gap-2 text-sm"
                                >
                                    <Sparkles className="w-4 h-4" />
                                    Iniciar Smart Buy
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="p-4 md:p-6 space-y-4 md:space-y-6">
                        <div className="flex flex-col items-center">
                            <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-background flex items-center justify-center mb-3 md:mb-4">
                                {purchaseStatus === "success" ? (
                                    <CheckCircle className="w-7 h-7 md:w-8 md:h-8 text-customGreen" />
                                ) : allAttemptsFailed ? (
                                    <XCircle className="w-7 h-7 md:w-8 md:h-8 text-destructive" />
                                ) : (
                                    <Loader2 className="w-7 h-7 md:w-8 md:h-8 text-primary animate-spin" />
                                )}
                            </div>

                            <h3 className="text-base md:text-lg font-medium text-center mb-4 text-card-foreground px-2">
                                {purchaseStatus === "success"
                                    ? "Compra Realizada com Sucesso!"
                                    : allAttemptsFailed
                                      ? "Todas as tentativas foram realizadas"
                                      : purchaseStatus === "error"
                                        ? "Buscando próximo cartão..."
                                        : "Processando transação..."}
                            </h3>

                            <div className="w-full max-w-xs md:max-w-sm">
                                <div className="h-2 w-full bg-background rounded-full overflow-hidden">
                                    <div
                                        className={`h-full transition-all duration-500 rounded-full ${
                                            purchaseStatus === "success"
                                                ? "bg-customGreen"
                                                : allAttemptsFailed
                                                  ? "bg-destructive"
                                                  : "bg-primary"
                                        }`}
                                        style={{
                                            width: `${((currentStep + 1) * 100) / allCards.length}%`,
                                        }}
                                    />
                                </div>
                                <p className="text-xs md:text-sm text-center text-muted-foreground mt-2">
                                    {purchaseStatus === "success"
                                        ? "Smart Buy finalizado com sucesso!"
                                        : allAttemptsFailed
                                          ? "Processo finalizado"
                                          : `Tentativa ${currentStep + 1} de ${allCards.length}`}
                                </p>
                            </div>
                        </div>

                        {currentCard && (
                            <div className="bg-background rounded-lg p-3 md:p-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground" />
                                        <div>
                                            <h4 className="text-sm md:text-base font-medium text-card-foreground flex items-center gap-2">
                                                {currentCard.bin}
                                                {showError && (
                                                    <XCircle className="w-4 h-4 text-destructive" />
                                                )}
                                                {purchaseStatus ===
                                                    "success" && (
                                                    <CheckCircle className="w-4 h-4 text-customGreen" />
                                                )}
                                            </h4>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Válido até {currentCard.month}/
                                                {currentCard.year}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`px-2 md:px-3 py-1 rounded-full text-xs font-medium ${
                                            purchaseStatus === "success"
                                                ? "bg-background/50 text-customGreen"
                                                : showError
                                                  ? "bg-background/50 text-destructive"
                                                  : "bg-background/50 text-primary"
                                        }`}
                                    >
                                        {purchaseStatus === "success"
                                            ? "Aprovado"
                                            : showError
                                              ? "Falhou"
                                              : "Processando"}
                                    </div>
                                </div>
                            </div>
                        )}

                        {(allAttemptsFailed ||
                            purchaseStatus === "success") && (
                            <div className="flex justify-center">
                                <Button
                                    onClick={handleClose}
                                    variant="outline"
                                    className="w-full max-w-[160px] md:max-w-[200px] text-sm"
                                >
                                    Fechar
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

// Novo componente para exibir o cartão compartilhado em um modal
const SharedCardModal = ({ cardId, onClose }) => {
    const [card, setCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCardDetails = async () => {
            try {
                const response = await axiosInstance.get(`/card/${cardId}`);
                setCard(response.data);
            } catch (err) {
                console.error("Erro ao obter detalhes do cartão:", err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchCardDetails();
    }, [cardId]);

    if (loading) return null;
    if (error) return null;
    if (!card) return null;

    return (
        <Modal isOpen={true} onClose={onClose}>
            <h2 className="text-2xl font-bold mb-4">
                Detalhes do Cartão Compartilhado
            </h2>
            <div className="mb-2">
                <strong>BIN:</strong> {DOMPurify.sanitize(card.bin)}
            </div>
            <div className="mb-2">
                <strong>Validade:</strong> {DOMPurify.sanitize(card.month)}/
                {DOMPurify.sanitize(card.year)}
            </div>
            <div className="mb-2">
                <strong>Vendor:</strong> {DOMPurify.sanitize(card.vendor)}
            </div>
            <div className="mb-2">
                <strong>Tipo:</strong> {DOMPurify.sanitize(card.type)}
            </div>
            <div className="mb-2">
                <strong>Nível:</strong> {DOMPurify.sanitize(card.level)}
            </div>
            <div className="mb-2">
                <strong>Banco:</strong> {DOMPurify.sanitize(card.bank)}
            </div>
            <div className="mb-2">
                <strong>País:</strong> {DOMPurify.sanitize(card.country)}
            </div>
            <div className="mb-2">
                <strong>Nome:</strong> {DOMPurify.sanitize(card.nome)}
            </div>
            <div className="mb-2">
                <strong>CPF:</strong> {DOMPurify.sanitize(card.cpf)}
            </div>
            {/* Botão para fechar o modal */}
            <Button variant="default" onClick={onClose} className="mt-4">
                Fechar
            </Button>
        </Modal>
    );
};

// Componente para lidar com a lógica principal e detecção do parâmetro de consulta
const MainContent = ({
    currentPage,
    setCurrentPage,
    onLogout,
    username,
    purchaseMode,
    updatePurchaseMode,
    userBalance,
    setUserBalance,
}) => {
    const location = useLocation();
    const [sharedCardId, setSharedCardId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Estado para armazenar o token CSRF
    const [csrfToken, setCsrfTokenState] = useState("");

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axiosInstance.get("/csrf-token");
                const { csrf_token } = response.data;
                setCsrfTokenState(csrf_token);
                setCsrfToken(csrf_token); // Define o token nos headers do axios
            } catch (error) {
                console.error("Erro ao obter o token CSRF:", error);
            }
        };

        // Obter o token CSRF apenas se o usuário estiver autenticado
        if (username) {
            fetchCsrfToken();
        }
    }, [username]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const cardId = params.get("card");
        if (cardId) {
            setSharedCardId(cardId);
            setIsModalOpen(true);
        } else {
            setSharedCardId(null);
            setIsModalOpen(false);
        }
    }, [location.search]);

    return (
        <>
            <CardInterface
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onLogout={onLogout}
                username={username}
                Button={Button}
                Input={Input}
                purchaseMode={purchaseMode}
                updatePurchaseMode={updatePurchaseMode}
                userBalance={userBalance}
                setUserBalance={setUserBalance}
            />
            {sharedCardId && isModalOpen && (
                <SharedCardModal
                    cardId={sharedCardId}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </>
    );
};

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentPage, setCurrentPage] = useState("main");
    const [username, setUsername] = useState(null);
    const [purchaseMode, setPurchaseMode] = useState(null);
    const [userBalance, setUserBalance] = useState(0);

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserBalance();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const fetchUserBalance = async () => {
        try {
            const response = await axiosInstance.get("/user-info");
            // Add null check and default value
            const balance = response.data?.user_info?.balance || 0;
            setUserBalance(balance);
            localStorage.setItem("userBalance", balance.toString());
        } catch (error) {
            console.error("Erro ao obter saldo do usuário:", error);
            setUserBalance(0);
            localStorage.setItem("userBalance", "0");
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            const fetchUserSettings = async () => {
                try {
                    const response = await axiosInstance.get("/user-settings");
                    // Add null check and default value
                    setPurchaseMode(
                        response.data?.settings?.purchaseMode || "cart",
                    );
                } catch (error) {
                    console.error("Erro ao buscar configurações:", error);
                    setPurchaseMode("cart");
                }
            };
            fetchUserSettings();
        } else {
            // Reset states when not authenticated
            setPurchaseMode("cart");
            setUserBalance(0);
            setCurrentPage("main");
        }
    }, [isAuthenticated]);

    const updatePurchaseMode = (mode) => {
        setPurchaseMode(mode);
        localStorage.setItem("purchaseMode", mode);
    };

    const checkAuthentication = () => {
        const token = localStorage.getItem("token");

        if (!token) {
            setIsAuthenticated(false);
            setUsername(null);
            return;
        }

        try {
            // Decodificar o token
            const decoded = jwtDecode(token);

            // Verificar se o token expirou
            if (decoded.exp * 1000 < Date.now()) {
                // Token expirado
                localStorage.removeItem("token");
                setAuthToken(null);
                setIsAuthenticated(false);
                setUsername(null);
                return;
            }

            // Token válido
            setAuthToken(token);
            setIsAuthenticated(true);
            setUsername(decoded.user);
        } catch (error) {
            console.error("Erro ao verificar autenticação:", error);
            localStorage.removeItem("token");
            setAuthToken(null);
            setIsAuthenticated(false);
            setUsername(null);
        }
    };

    useEffect(() => {
        checkAuthentication();

        // Verificar a cada 5 minutos
        const interval = setInterval(checkAuthentication, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUsername(decoded.user);
                setAuthToken(token); // Define o token nos headers do axios
            } catch (error) {
                console.error("Erro ao decodificar o token:", error);
                setUsername(null);
                setAuthToken(null); // Remove o token dos headers do axios
            }
        }
        setCurrentPage("main");
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setUsername(null);
        setCurrentPage("main");
        setAuthToken(null); // Remove o token dos headers do axios
    };

    return (
        <Router>
            <div className="app-container">
                {isAuthenticated ? (
                    <MainContent
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onLogout={handleLogout}
                        username={username}
                        purchaseMode={purchaseMode}
                        updatePurchaseMode={updatePurchaseMode}
                        userBalance={userBalance}
                        setUserBalance={setUserBalance}
                    />
                ) : (
                    <AstraShopLogin onLoginSuccess={handleLoginSuccess} />
                )}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                style={{
                    top: "7rem", // Dá espaço suficiente para não sobrepor o saldo/carrinho
                    right: "1rem",
                }}
            />
        </Router>
    );
};

export default App;
