import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosConfig";
import {
    User,
    Lock,
    LogIn,
    UserPlus,
    CheckCircle,
    XCircle,
    Eye,
    EyeOff,
} from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import { motion, AnimatePresence } from "framer-motion";

const RECAPTCHA_SITE_KEY = "6LeAuGIqAAAAAF8fJyFuoTvtqpKxIULOjw_KnXgR";

const Button = ({
    children,
    type = "button",
    onClick,
    className,
    disabled,
    icon: Icon,
}) => {
    const baseStyle =
        "flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring";
    return (
        <button
            type={type}
            onClick={onClick}
            className={`${baseStyle} ${className}`}
            disabled={disabled}
        >
            {Icon && <Icon className="w-5 h-5" />}
            {children}
        </button>
    );
};

const Input = ({
    id,
    name,
    type = "text",
    className,
    placeholder,
    value,
    onChange,
}) => {
    const baseStyle =
        "w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-0 focus:border-blue-500";
    return (
        <input
            id={id}
            name={name}
            type={type}
            className={`${baseStyle} ${className}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    );
};

const Card = ({ children, className }) => {
    return (
        <div className={`bg-gray-800 rounded-lg shadow-xl ${className}`}>
            {children}
        </div>
    );
};

const CardHeader = ({ children }) => {
    return <div className="p-4 border-b border-gray-700">{children}</div>;
};

const CardContent = ({ children }) => {
    return <div className="p-4">{children}</div>;
};

const CardFooter = ({ children }) => {
    return <div className="p-4 border-t border-gray-700">{children}</div>;
};

export default function AstraShopLogin({ onLoginSuccess }) {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [csrfToken, setCsrfToken] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axiosInstance.get("/csrf-token");
                setCsrfToken(response.data.csrf_token);
            } catch (error) {
                console.error("Erro ao obter o token CSRF", error);
            }
        };

        fetchCsrfToken();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccessMessage("");
        setIsLoading(true);

        if (!formData.username.trim() || !formData.password.trim()) {
            setError("Usuário e senha são obrigatórios.");
            setIsLoading(false);
            return;
        }

        if (!recaptchaToken) {
            setError("Por favor, verifique que você não é um robô.");
            setIsLoading(false);
            return;
        }

        try {
            const endpoint = isRegistering ? "register" : "login";
            const payload = {
                ...formData,
                recaptcha_token: recaptchaToken,
            };
            const response = await axiosInstance.post(`/${endpoint}`, payload, {
                headers: {
                    "X-CSRFToken": csrfToken,
                },
            });

            if (isRegistering) {
                setSuccessMessage("Registro bem-sucedido!");
                setIsRegistering(false);
                setFormData({ username: "", password: "" });
            } else if (response.data.token) {
                localStorage.setItem("token", response.data.token);
                onLoginSuccess();
                console.log("Login bem-sucedido!");
            } else {
                setError("Erro inesperado. Por favor, tente novamente.");
            }
        } catch (err) {
            console.error("Erro na submissão:", err);

            if (err.response) {
                const status = err.response.status;
                const data = err.response.data;

                if (data && data.error) {
                    setError(data.error);
                } else if (status === 401) {
                    setError("Credenciais inválidas.");
                } else if (status === 400) {
                    setError("Requisição inválida.");
                } else {
                    setError(
                        "Erro ao processar a solicitação. Tente novamente.",
                    );
                }
            } else if (err.request) {
                console.error("Erro na requisição:", err.request);
                setError(
                    "Sem resposta do servidor. Tente novamente mais tarde.",
                );
            } else {
                console.error("Erro desconhecido:", err.message);
                setError("Ocorreu um erro. Tente novamente.");
            }
        } finally {
            setIsLoading(false);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            setRecaptchaToken(null);
        }
    };

    const handleToggle = () => {
        setIsRegistering(!isRegistering);
        setError("");
        setSuccessMessage("");
        setFormData({ username: "", password: "" });
        setShowPassword(false);
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
        setRecaptchaToken(null);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <Card className="w-full max-w-md p-8 space-y-8 bg-gray-800 rounded-lg shadow-xl">
                <CardHeader>
                    <div className="text-center">
                        <div className="flex justify-center">
                            {/* Seu logotipo aqui */}
                        </div>
                        <h2 className="mt-6 text-3xl font-bold text-white">
                            AstraShop
                        </h2>
                        <h3 className="mt-2 text-xl text-gray-300">
                            {isRegistering
                                ? "Crie sua conta"
                                : "Bem-vindo ao AstraShop"}
                        </h3>
                    </div>
                </CardHeader>

                <CardContent>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div
                            key={isRegistering ? "register" : "login"}
                            initial={{
                                opacity: 0,
                                x: isRegistering ? 50 : -50,
                            }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: isRegistering ? -50 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            {successMessage && (
                                <div className="flex items-center justify-center mb-4">
                                    <span className="flex items-center bg-green-500 text-white text-sm font-medium px-4 py-2 rounded-full">
                                        <CheckCircle className="w-4 h-4 mr-2" />
                                        {successMessage}
                                    </span>
                                </div>
                            )}

                            {error && (
                                <div className="flex items-center justify-center mb-4">
                                    <span className="flex items-center bg-red-500 text-white text-sm font-medium px-4 py-2 rounded-full">
                                        <XCircle className="w-4 h-4 mr-2" />
                                        {error}
                                    </span>
                                </div>
                            )}

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="space-y-4">
                                    <div className="relative">
                                        <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                                        <Input
                                            id="username"
                                            name="username"
                                            type="text"
                                            required
                                            className="pl-10 bg-gray-700 text-white border-gray-600"
                                            placeholder="Digite seu usuário"
                                            value={formData.username}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="relative">
                                        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                                        <Input
                                            id="password"
                                            name="password"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            required
                                            className="pl-10 pr-12 bg-gray-700 text-white border-gray-600"
                                            placeholder="Digite sua senha"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                        />
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-300 focus:outline-none"
                                        >
                                            {showPassword ? (
                                                <EyeOff className="h-5 w-5" />
                                            ) : (
                                                <Eye className="h-5 w-5" />
                                            )}
                                        </button>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={handleRecaptchaChange}
                                    ref={recaptchaRef}
                                    theme="dark"
                                />

                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full"
                                    icon={isRegistering ? UserPlus : LogIn}
                                >
                                    {isLoading
                                        ? isRegistering
                                            ? "Registrando..."
                                            : "Entrando..."
                                        : isRegistering
                                          ? "Registrar"
                                          : "Entrar"}
                                </Button>
                            </form>
                        </motion.div>
                    </AnimatePresence>
                </CardContent>

                <CardFooter className="flex flex-col items-center">
                    <button
                        onClick={handleToggle}
                        className="flex items-center justify-center w-full text-blue-500 hover:text-blue-600 transition-colors duration-200 mt-4"
                    >
                        {isRegistering ? (
                            <>
                                <LogIn className="w-5 h-5 mr-2" />
                                <span>Já é membro? Faça login</span>
                            </>
                        ) : (
                            <>
                                <UserPlus className="w-5 h-5 mr-2" />
                                <span>Novo por aqui? Crie sua conta</span>
                            </>
                        )}
                    </button>
                    <p className="mt-8 text-center text-sm text-gray-400">
                        ©2024 AstraShop. Todos os direitos reservados.
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
}
