// src/axiosConfig.js
import axios from "axios";
import { toast } from "react-toastify";

// Função de debug
const debugLog = (message, data = null) => {
    console.log(`[Debug] ${message}`, data || "");
};

// Criação de uma instância do Axios
const axiosInstance = axios.create({
    baseURL: "https://api.astrashopbot.cc/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

// Função para definir o token de autenticação
export const setAuthToken = (token) => {
    debugLog("Setting auth token:", token ? "Token present" : "Token removed");

    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] =
            `Bearer ${token}`;
        localStorage.setItem("token", token);
    } else {
        delete axiosInstance.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
    }
};

// Função para definir o token CSRF
export const setCsrfToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common["X-CSRFToken"] = token;
    } else {
        delete axiosInstance.defaults.headers.common["X-CSRFToken"];
    }
};

// Função para verificar se o token JWT é válido
const isTokenValid = (token) => {
    if (!token) return false;

    try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expiry = payload.exp * 1000; // Converter para milissegundos
        return Date.now() < expiry;
    } catch (e) {
        debugLog("Error validating token:", e);
        return false;
    }
};

// Inicialização do token
const initializeAuth = () => {
    debugLog("Initializing auth");
    const token = localStorage.getItem("token");

    if (token && isTokenValid(token)) {
        debugLog("Valid token found, setting up auth");
        setAuthToken(token);
        return true;
    } else {
        debugLog("No valid token found");
        clearSessionData();
        return false;
    }
};

// Função para limpar dados da sessão
const clearSessionData = () => {
    debugLog("Clearing session data");

    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    localStorage.removeItem("userBalance");
    localStorage.removeItem("purchaseMode");

    delete axiosInstance.defaults.headers.common["Authorization"];
    delete axiosInstance.defaults.headers.common["X-CSRFToken"];
};

// Função de logout
const handleLogout = () => {
    debugLog("Handling logout");
    clearSessionData();
    window.location.href = "/";
};

// Interceptor de requisição
axiosInstance.interceptors.request.use(
    (config) => {
        debugLog(`Making request to: ${config.url}`);
        const token = localStorage.getItem("token");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        debugLog("Request error:", error);
        return Promise.reject(error);
    },
);

// Interceptor de resposta
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        debugLog("Response error:", error);

        if (error.response?.status === 401) {
            // Verificar se não é uma requisição de autenticação
            if (
                !error.config.url.includes("/login") &&
                !error.config.url.includes("/register")
            ) {
                debugLog("Unauthorized request detected");
                clearSessionData();
                window.location.href = "/";
            }
        }

        return Promise.reject(error);
    },
);

// Inicializar autenticação
initializeAuth();

export default axiosInstance;
